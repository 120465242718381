<template>
    <div class="clock w-full h-full">{{ time }}</div>
</template>
<script setup>
import { onMounted, ref } from 'vue';

const time = ref()

const updateClock = () => {
    var now = new Date();
    var hours = String(now.getHours()).padStart(2, '0');
    var minutes = String(now.getMinutes()).padStart(2, '0');
    var seconds = String(now.getSeconds()).padStart(2, '0');
    time.value = hours + ":" + minutes + ":" + seconds;
}

onMounted(() => setInterval(updateClock, 1000));

</script>
<style>
.clock {
    font-family: 'Courier New', monospace;
    color: #2c804c;
    font-size: 36px;
    text-align: center;
    font-weight: 900;
    border-color: #dfdfdf;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>