<template>
  <div class="flex justify-content-center">
    <Toast />
    <div class="main-content px-4" style="max-width: 100rem;">
      <div class="flex justify-content-center flex-column pt-2 mb-2">
        <Image :src="logoSrc" width="48" class="text-center mt-2 mb-2" />
        <span class="text-4xl text-center font-bold mt-2 mb-2">
          {{ (info.name ?? 'Easy Uptime Tool') + ' v' + info.version }}
        </span>
      </div>
      <InfoSection />
      <AppDashboard />
      <div class="footer-info">
        <span>FOR MidOpt INTERNAL USE ONLY | BUILD <b>{{ info.build }}</b> | {{ currentYear }}</span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import Toast from 'primevue/toast';
import Image from 'primevue/image';
import logo from '@/assets/logo.png';
import InfoSection from '@/components/InfoSection.vue';
import AppDashboard from '@/components/AppDashboard.vue';
import { getAppInfo } from '@/services/ApiService';

const info = ref({});
const logoSrc = logo;

onMounted(async () => {
  info.value = await getAppInfo();
});

const currentYear = computed(() => new Date().getFullYear());

</script>

<style scoped>
body {
  font-size: 2em;
}

.main-content {
  display: flex;
  flex-direction: column;
  /* height: 95vh; */
  overflow: hidden;
}

.footer-info {
  font-size: 0.75rem;
  color: black;
  margin-top: 1rem;
  align-self: center;
}
</style>
