<template>
    <Card class="status-card m-2 border-round pointer shadow-4" @click="onClickAction">
        <template #title>
            <div class="flex flex-column">
                <span>{{ title }}</span>
                <span v-if="description" class="text-xs font-normal">({{ description }})</span>
            </div>
        </template>
        <template #content>
            <div v-for="s, k in status" :key="k" class="flex flex-column status gap-2">
                <span class="flex justify-content-center align-items-center gap-1">
                    <span v-if="itemTitle">
                        <span>{{ s.title }}</span><span>-</span>
                    </span>
                    <Badge size="large" class="status-badge" :class="statusSeverity(s.status) + '-badge'" :severity="statusSeverity(s.status)"></Badge>
                    <span :class="statusClass(s.status)">{{ statusMessage(s.status) }}</span>
                </span>
                <span class="text-xs" :class="statusClass(s.status)">{{ statusDescription(k) }}</span>
            </div>
        </template>
    </Card>
</template>
  
<script setup>
import Card from 'primevue/card';
import Badge from 'primevue/badge';

const props = defineProps({
    title: String,
    status: Array,
    link: String,
    itemTitle: Boolean,
    description: String,
});

const statusClass = (status) => {
    switch (status) {
        case 'none':
        case 'green':
        case 'online':
        case 'operational':
        case 'available':
            return 'status-online';
        case 'red':
        case 'offline':
            return 'status-offline';
        case 'maintenance':
            return 'status-maintenance';
        default:
            return 'status-' + status;
    }
};

const statusSeverity = (status) => {
    switch (status) {
        case 'none':
        case 'green':
        case 'online':
        case 'operational':
        case 'available':
            return 'success';
        case 'red':
        case 'offline':
            return 'danger';
        case 'yellow':
        case 'maintenance':
        case 'partial_outage':
        case 'degraded_performance':
            return 'warning';
        default:
            return 'default';
    }
};

const statusMessage = (status) => {
    switch (status) {
        case 'none':
        case 'green':
        case 'online':
        case 'operational':
        case 'available':
            return 'Online';
        case 'red':
        case 'offline':
            return 'Major Outage';
        case 'yellow':
        case 'maintenance':
        case 'partial_outage':
        case 'degraded_performance':
            return 'Partial Outage';
        case 'unavailable':
            return 'Unavailable';
        default:
            return status;
    }
};

const statusDescription = (key) => {
    return props.status[key].description;
};

const onClickAction = () => {
    window.open(props.link, '_blank');
};
</script>
  
<style scoped>
.status {
    font-size: 1.2em;
    font-weight: bold;
}

.status-card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20em;
    cursor: pointer;
}

.status-online,
.status-none {
    color: green;
}

.status-major,
.status-red,
.status-offline {
    color: red;
}

.status-minor,
.status-yellow,
.status-maintenance {
    color: orange;
}

.status-unknown {
    color: grey;
}

.status-badge {
    font-size: 1rem;
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
}

.default-badge {
    background-color: grey !important;
}
</style>
  