import axios from "axios";

const apiClient = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
});

export const getAppInfo = async () => {
    try {
        const response = await apiClient.get('api/info');
        return response.data;
    } catch (error) {
        console.error('Error fetching service statuses:', error);
    }
};

export const getStatuses = async () => {
    try {
        const response = await apiClient.get('api/statuses');
        return response.data;
    } catch (error) {
        console.error('Error fetching service statuses:', error);
    }
};