<template>
    <div class="tomorrow w-full" data-location-id="" data-language="EN" data-unit-system="IMPERIAL" data-skin="light"
        data-widget-type="aqiMini" style="">
        <a href="https://www.tomorrow.io/weather-api/" rel="nofollow noopener noreferrer" target="_blank"
            style="position: absolute; bottom: 0; transform: translateX(-60%); left: 50%;">
            <img alt="Powered by the Tomorrow.io Weather API"
                src="https://weather-website-client.tomorrow.io/img/powered-by.svg" width="250" height="0" />
        </a>
    </div>
</template>
<script>
(function (d, s, id) {
    if (d.getElementById(id)) {
        if (window.__TOMORROW__) {
            window.__TOMORROW__.renderWidget();
        }
        return;
    }
    const fjs = d.getElementsByTagName(s)[0];
    const js = d.createElement(s);
    js.id = id;
    js.src = "https://www.tomorrow.io/v1/widget/sdk/sdk.bundle.min.js";

    fjs.parentNode.insertBefore(js, fjs);
})(document, 'script', 'tomorrow-sdk');

</script>
<style scoped></style>