import { createApp } from 'vue';
import App from './App.vue';
import store from "./store";
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';

import "primeflex/primeflex.css";
import 'primeicons/primeicons.css';
import "primevue/resources/themes/lara-light-purple/theme.css";

const app = createApp(App);

if (process.env.NODE_ENV === 'production') {
    app.config.devtools = false;
}

app.use(store)
    .use(ToastService)
    .use(PrimeVue, { ripple: true });

app.mount('#app');