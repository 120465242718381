<template>
  <div class="dashboard text-center" style="min-height:40vh;">
      <div class="flex flex-wrap justify-content-center">
        <ServiceStatus v-for="s, key in services" :key="key" v-show="!unknownStatus(s)" :title="s.title"
          :description="s.description" :link="s.link" :status="s.status" :itemTitle="s.cptitle"/>
        <ServiceStatus v-for="s, key in services" :key="key" v-show="unknownStatus(s)" :title="s.title"
          :description="s.description" :link="s.link" :status="s.status" :itemTitle="s.cptitle" />
      </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import ServiceStatus from './ServiceStatus.vue';
// import ScrollPanel from 'primevue/scrollpanel';
import { getStatuses } from '@/services/ApiService';

const services = ref({});

onMounted(async () => {
  services.value = await getStatuses();
});

const unknownStatus = (service) => {
  try {
    return service.status[0].status == 'unknown';
  } catch {
    return true;
  }
};
</script>

<style></style>
