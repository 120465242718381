<template>
    <div>
        <div class="day-display">
            {{ currentDay }}
        </div>
        <div class="date-display">
            {{ currentDate }}
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
const currentDate = ref(new Date().toLocaleDateString());
const currentDay = ref(new Date().toLocaleDateString('en-US', { weekday: 'long' }));

onMounted(() => {
    setInterval(() => {
        currentDate.value = new Date().toLocaleDateString();
    }, 1000);
});
</script>

<style scoped></style>