<template>
    <div class="flex align-content-between justify-content-between flex-row md:flex-column h-full">
        <LocalDateComponent />
        <TieredMenu ref="menu" id="overlay_tmenu" class="" :model="items" :popup="true" />
        <Button type="button" label="Apps" @click="toggle" aria-haspopup="true" icon="pi pi-angle-double-down"
            aria-controls="overlay_tmenu" rounded />
    </div>
</template>

<script setup>
import { ref } from 'vue';
import TieredMenu from 'primevue/tieredmenu';
import Button from 'primevue/button';
import LocalDateComponent from '@/components/LocalDateComponent.vue';

const menu = ref();
const items = ref([
    {
        label: 'Acumatica',
        icon: 'pi pi-briefcase',
        command: () => window.open(url['acumatica'], '_blank'),
    },
    {
        label: 'Gmail',
        icon: 'pi pi-at',
        command: () => window.open(url['gmail'], '_blank'),
    },
    {
        label: 'Calendar',
        icon: 'pi pi-calendar',
        command: () => window.open(url['calendar'], '_blank'),
    },
    {
        label: 'Google Drive',
        icon: 'pi pi-cloud',
        command: () => window.open(url['drive'], '_blank'),
    },
    {
        label: 'Box',
        icon: 'pi pi-box',
        command: () => window.open(url['box'], '_blank'),
    },
    {
        separator: true
    },
    {
        label: 'IT Help Ticket',
        icon: 'pi pi-ticket',
        command: () => window.open(url['ithelp'], '_blank'),
    },
]);
const url = {
    'box': 'https://box.com',
    'gmail': 'https://mail.google.com',
    'drive': 'https://drive.google.com',
    'calendar': 'https://calendar.google.com',
    'acumatica': 'https://midopt.acumatica.com',
    'ithelp': 'https://www.help.midopt.com/it-help',
};
const toggle = (event) => {
    menu.value.toggle(event);
};
</script>

<style scoped></style>